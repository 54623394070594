@import './mixins/vertical-align';
@import './base';

.date-input-container {
  position: relative;
}

.date-input-container__input {
  padding-right: 35px !important;
}

.date-input-container__input:not(:disabled) {
  background-color: #fff !important;
}

.date-input-container__icon {
  @include vertical-align();

  right: .75rem;
  cursor: pointer;
}

.react-datepicker__close-icon.react-datepicker__close-icon {
  @include vertical-align();

  right: 0;
}

.react-datepicker__close-icon.react-datepicker__close-icon:after {
  right: .75rem;
  line-height: 11px;
  background-color: #333;
  font-size: 14px;
}
