@import '../../utils/base';
@import '../../utils/mixins/vertical-align';

.short-urls-row {
  @media (max-width: $smMax) {
    display: block;
    margin-bottom: 10px;
    border-bottom: 1px solid $lightGrey;
    position: relative;
  }
}

.short-urls-row__cell.short-urls-row__cell {
  vertical-align: middle !important;

  @media (max-width: $smMax) {
    display: block;
    width: 100%;
    position: relative;
    padding: .5rem;
    font-size: .9rem;

    &:before {
      content: attr(data-th);
      font-weight: bold;
    }

    &:last-child {
      position: absolute;
      top: 3.5px;
      right: .5rem;
      width: auto;
      padding: 0;
      border: none;
    }
  }
}

.short-urls-row__cell--break {
  word-break: break-all;
}

.short-urls-row__cell--relative {
  position: relative;
}

.short-urls-row__cell--big {
  transform: scale(1.5);
}

.short-urls-row__copy-btn {
  cursor: pointer;
  font-size: 1.2rem;
}

.short-urls-row__copy-hint {
  @include vertical-align(translateX(10px));
  box-shadow: 0 3px 15px rgba(0, 0, 0, .25);

  @media (max-width: $smMax) {
    @include vertical-align(translateX(calc(-100% - 20px)));
  }
}
