.sorting-dropdown__menu {
  width: 100%;
}

.sorting-dropdown__menu--link.sorting-dropdown__menu--link {
  min-width: 11rem;
}

.sorting-dropdown__sort-icon {
  margin: 3.5px 0 0;
  float: right;
}
