.tag-card.tag-card {
  margin-bottom: .5rem;
}

.tag-card__header.tag-card__header {
  background-color: #eee;
}

.tag-card__header.tag-card__header,
.tag-card__body.tag-card__body {
  padding: .75rem;
}

.tag-card__tag-title {
  margin: 0;
  line-height: 31px;
  padding-right: 5px;
}

.tag-card__btn {
  float: right;
}

.tag-card__btn--last {
  margin-left: 3px;
}

.tag-card__table-cell.tag-card__table-cell {
  border: none;
}

.tag-card__tag-name {
  color: #007bff;
  cursor: pointer;
}

.tag-card__tag-name:hover {
  color: #0056b3;
  text-decoration: underline;
}
