@import './utils/base';

.app-container {
  height: 100%;
}

.app {
  padding-top: $headerHeight;
  height: 100%;
}
