@import '../utils/base';

.menu-layout__swipeable {
  $offset: 15px;

  height: 100%;
  margin-right: -$offset;
  margin-left: -$offset;
  padding-left: $offset;
  padding-right: $offset;
}

.menu-layout__swipeable-inner {
  height: 100%;
}

.menu-layout__burger-icon {
  display: none;
  transition: color 300ms;
  position: fixed;
  top: 18px;
  z-index: 1035;
  font-size: 1.5rem;
  cursor: pointer;
  color: rgba(255, 255, 255, .5);

  @media (max-width: $smMax) {
    display: inline-block;
  }
}

.menu-layout__burger-icon--active {
  color: white;
}

$footer-height: 2.3rem;
$footer-margin: .8rem;

.menu-layout__container {
  padding: 20px 0 ($footer-height + $footer-margin);
  min-height: 100%;
  margin-bottom: -($footer-height + $footer-margin);

  @media (min-width: $mdMin) {
    padding: 30px 15px ($footer-height + $footer-margin);
  }
}

.menu-layout__footer {
  height: $footer-height;
  margin-top: $footer-margin;
  padding: 0;

  @media (min-width: $mdMin) {
    padding: 0 15px;
  }
}
